import { createContext, useState, useContext, useEffect } from "react";
import ApiUsers from "../ApiUsers";
import  { UserContext } from "./userProvider";

export const TransmissaoContext = createContext();

export function TransmissaoProvider({ children }) {
  const [isRecording, setIsRecording] = useState(false);
  const [egress, setEgress] = useState(null);
  const [urlRecording, setUrlRecording] = useState('null');
  const { socket, room, isAdmin } = useContext(UserContext);

  const startRecording = async () => {
    console.log("startRecording");
    const result = await ApiUsers.post("/recording/start", {
      roomName: room,
    });
    console.log(result);
    //setEgress(response.data.egress);
  };

  const stopRecording = async () => {
    console.log("stopRecording"); 
    const response = await ApiUsers.post("/recording/stop", {
      egress,
    });
    //setUrlRecording(response.data.recording);
    //setIsRecording(false);
  };

  useEffect(() => {
    if (room && isRecording && isAdmin) {
      console.log("Condições atendidas, iniciando temporizador para gravação.");
      const timer = setTimeout(() => {
        console.log("Executando startRecording após 15 segundos.");
        startRecording();
      }, 15000);
      return () => {
        console.log("Limpando temporizador da gravação.");
        clearTimeout(timer);
      };
    }
  }, [room, isRecording]);

  return (
    <TransmissaoContext.Provider
      value={{  
        stopRecording, 
        setIsRecording, isRecording,
        //urlRecording
      }}
    >
      {children}
    </TransmissaoContext.Provider>
  );
}
